import "./src/styles/app.scss"
import "./node_modules/@fontsource/lato/400.css"
import "./node_modules/@fontsource/lato/400-italic.css"
import "./node_modules/@fontsource/lato/700.css"
import "./node_modules/@fontsource/lato/700-italic.css"
import "./node_modules/@fontsource/playfair-display/700.css"
// import "https://fonts.googleapis.com/css?family=Playfair+Display|Lato:ital,wght@0,400;0,700;1,400&display=swap"

import React from "react"
import Layout from "./src/components/layout";

const transitionDelay = 500;

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    );
  }
  return false;
};