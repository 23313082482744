import React, { Component, Suspense, lazy } from "react"
import { motion, AnimatePresence } from "framer-motion"
import AOS from "aos"
const Header = lazy(() => import("./header"))
const Footer = lazy(() => import("./footer"))


const duration = 0.5

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
}

const Layout = ({ children, location }) => (
<div>
  <Suspense fallback={<div style={{display: 'block', position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', background: '#fead52', zIndex: 1000, opacity: 1, transition: '.1s all', transitionDelay: '.1s'}}></div>}>
    <Header />
    <AnimatePresence onExitComplete={()=>AOS.refresh()}>
        <motion.main
          key={location.pathname}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        >	
  			{children}
  		</motion.main>
    </AnimatePresence>
  	<Footer />
  </Suspense>
</div>
)

export default Layout